import clsx from 'clsx';
import React from 'react';

import { Heading } from '../heading/Heading';
import { IconCircle } from '../icon/IconCircle';
import { Item, ItemAside, ItemBody } from './Item';

interface IconItemProps {
  icon: string;
  iconSize?: string | number;
  iconCircle?: string | 8 | 16 | 32;
  title?: string;
  info?: string;
  className?: string;
}

export const IconItem: React.FC<IconItemProps> = (props) => {
  const { title, icon, iconCircle = 16, iconSize, info } = props;
  const { children, className } = props;

  return (
    <Item className={clsx('item--icon', className)}>
      <ItemAside>
        <IconCircle name={icon} size={iconCircle} iconSize={iconSize} />
      </ItemAside>
      <ItemBody>
        <Heading is="h4">{title}</Heading>
        {info && <p>{info}</p>}
        {children}
      </ItemBody>
    </Item>
  );
};
