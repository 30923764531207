import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { I18N_NAMESPACES } from '../../i18n';
import { Heading } from '../heading/Heading';
import { Loader } from '../loader/Loader';
import { IMasonryProps, Masonry } from './Masonry';

export interface MasonryContentBlockProps {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  title?: string;
  isLoading?: boolean;
  masonryProps?: IMasonryProps;
}

const MasonryContentBlock = (props: MasonryContentBlockProps) => {
  const { children, isLoading, masonryProps, title, className } = props;
  const { t } = useTranslation([I18N_NAMESPACES.ACADEMY]);

  return (
    <div className={clsx('flex flex-col relative mt-6', className)}>
      {title && (
        <Heading className="mb-6" is="h3">
          {t(title)}
        </Heading>
      )}
      {isLoading ? <Loader minHeight="sm" /> : <Masonry {...masonryProps}>{children}</Masonry>}
    </div>
  );
};

export default React.memo(MasonryContentBlock);
