import clsx from 'clsx';
import React from 'react';

import './FieldLabel.css';

export interface FieldLabelProps {
  id?: string;
  title?: string;
  subTitle?: string;
  required?: boolean;
  touched?: boolean;
  error?: string;
  className?: string;
  style?: React.CSSProperties;
  additional?: string;
}

export const FieldLabel: React.FC<FieldLabelProps> = React.memo((props) => {
  const { id, title, subTitle, required, className, style, additional, touched, error, children } = props;
  const BEM = clsx('field-label', className);

  return (
    <div className={BEM} style={style}>
      {title || subTitle ? (
        <label htmlFor={id} className="field-label__label">
          {title && (
            <strong className="field-label__title">
              {title} {required && <span className="text-red-500">*</span>}
            </strong>
          )}
          {subTitle || additional ? (
            <div className="flex justify-between">
              <div className="field-label__sub-title">{subTitle || null}</div>
              <div>{additional || null}</div>
            </div>
          ) : null}
        </label>
      ) : null}
      {children}
      {touched && error ? <p className="field-label__error">{error}</p> : null}
    </div>
  );
});
