import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { UploadableFileDTO } from '../../components/form/file-upload/FileUpload';
import { PERMISSION, ROLE } from '../../utils/permissions';
import { parseBase64ToDrupalFormat, parseCardRequestData } from '../../utils/request';
import { BulletinRouteProps } from '../../views/programme-database/components/Bulletin';
import { PROGRAMME_FORM_FIELDS } from '../../views/programme-database/components/ProgrammeForm';
import { CommunityCardValues } from '../../views/settings/components/community-cards/CommunityCardEdit';

// enums

export enum ARTICLE_RELATED_TOOL {
  PROFILE = '12247',
  BULLETIN = '12248',
  PROGRAMME_DATABASE = '12249',
  TTM_EVENT_MANAGER = '12549',
  E_ACADEMY = '12330',
  PUBLICATIONS = '12268',
  COMPETENCE_FRAMEWORK = '12291',
  QUALIFYING_TRAINING_PROGRAMME = '12304',
  EUROCLASSES = '12311',
  EMBED_TOOLS = '12321',
}

export enum CARD_TYPE {
  AUTOMATIC = 'card_automatic',
  STATISTICS = 'card_statistical_data',
  VIDEO = 'card_video',
  QUOTE = 'card_quote',
  IMAGE = 'card_image',
  ICON = 'card_icon',
  COUNTDOWN = 'card_countdown',
  POLL = 'card_poll',
}

export enum CARD_DATA_SOURCE {
  USER = 'user',
  PROGRAM = 'program',
  BULLETIN = 'bulletin',
  MODULE = 'module',
  STATISTICS = 'statistics',
  CALENDAR = 'google_calendar',
}

export enum CARD_DATA_SOURCE_STATISTICS {
  EVENT = 'event',
  PROGRAM = 'programme',
  USER = 'user',
}

export enum EToolType {
  WITH_BUTTONS = 'tool_with_buttons',
  WITH_ITEMS = 'tool_with_items',
}

export enum EToolDescriptionVisibility {
  TOOL_PAGE = 'tool_page',
  SEPARATE_PAGE = 'separate_page',
}

export enum USER_STATUS {
  APPROVED = 'approved',
  HIDDEN = 'hidden',
  DECLINED = 'declined',
  PENDING = 'pending',
}

export enum OFFICE_STATUS {
  HIDDEN = 'hidden',
  DECLINED = 'declined',
  APPROVED = 'approved',
  PENDING = 'pending',
}

export enum PROGRAMME_STATUS {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  PENDING = 'pending',
  ARCHIVED = 'archived',
}

export enum PROGRAMME_ONLINE_ONSITE {
  ONLINE = 'online',
  ONSITE = 'onsite',
}

// New categories are 2-level in Backend. TODO - Get only top level categories straight from Backend.
export const programmeCategoryIdMap: { [key: string]: number[] } = {
  '142': [147, 148, 149, 150],
  '143': [151, 152, 153, 154],
  '144': [155, 156, 157],
  '145': [158, 159],
  '146': [160, 161, 162, 163],
};

export enum TRANSLATION_STATUS {
  PUBLISHED = 'Published',
  OUTDATED = 'Outdated',
  NOT_TRANSLATED = 'Not translated',
}

export enum CARD_REQUEST_TYPE {
  NATIONAL = 1,
  COMMUNITY = 2,
}

export enum PUBLISHED_STATE {
  PUBLISHED = 1,
  UNPUBLISHED = 0,
}

export enum FUNCTIONS {
  EBL = 0,
  NATIONAL_COORDINATOR = 3,
  MULTIPLIER = 4,
  MOBILITY_GUIDE = 7,
  EURODESK_INFOPOINT = 10,
}

// Interfaces

export interface MapLocationDTO {
  bottom: number;
  geo_type: string;
  geohash: string;
  lat: number;
  latlon: string;
  left: number;
  lon: number;
  right: number;
  top: number;
  value: string;
}

export interface FunctionDTO {
  id: FUNCTIONS;
  title: string;
}

export type GetOfficeResponse = OfficeQueryDTO[];

export interface OfficeQueryDTO {
  id: number;
  type: string;
  geometry: { type: string; coordinates: number[] };
  properties: {
    office: OfficeDTO;
  };
}

export type EmailCheckResponse = { error: string | boolean };

export interface GetUserPendingCountResponse {
  approved: number;
  declined: number;
  pending: number;
  hidden: number;
}

export interface VideoDTO {
  youtube?: { input: string; video_id: string; videoThumbnail: string };
  videoMp4?: string;
  videoWebm?: string;
  videoThumbnail?: string;
  duration?: string;
}

export interface FileDTO {
  id: number;
  url: string;
  name: string;
  mimeType: string;
  size: string;
}

export interface PollDTO {
  id: number;
  isActive: boolean;
  allowAnonymousVotes: boolean;
  allowCancelVotes: boolean;
  allowViewResults: boolean;
  hasUserVoted: boolean;
  created: number;
  question: string;
  options: {
    [key: string]: string;
  };
  votes: {
    [key: string]: number;
  };
  slug: string;
}

export interface AutomaticCardUserDTO extends AutomaticCardBaseDTO<CARD_DATA_SOURCE.USER> {
  automatic: {
    id: number;
    name?: string;
    image?: string;
    office?: {
      id: number;
    };
    country?: { id: number; code: string; title: string };
  };
}

export interface AutomaticCardModuleDTO extends AutomaticCardBaseDTO<CARD_DATA_SOURCE.MODULE> {
  automatic: {
    id: number;
    title?: string;
    video?: VideoDTO;
    slug: string;
  };
}

export interface AutomaticCardProgramDTO extends AutomaticCardBaseDTO<CARD_DATA_SOURCE.PROGRAM> {
  automatic: { category: string; id: string; imageUrl: string; slug: string; title: string }[];
}

export interface AutomaticCardBulletinDTO extends AutomaticCardBaseDTO<CARD_DATA_SOURCE.BULLETIN> {
  automatic: { category: string; id: string; imageUrl: string; title: string }[];
}

export interface AutomaticCardCalendarDTO extends AutomaticCardBaseDTO<CARD_DATA_SOURCE.CALENDAR> {
  automatic: {
    description: string;
    googleEventId: string;
    googleLink: string;
    id: string;
    location: string;
    startTime: number;
    endTime: number;
    title: string;
  }[];
}

export interface AutomaticCardStatisticsDTO extends AutomaticCardBaseDTO<CARD_DATA_SOURCE.STATISTICS> {
  automatic: {
    total: number;
    country: number;
  };
}

export type AutomaticCardDTO<T = CARD_DATA_SOURCE> = T extends CARD_DATA_SOURCE.USER
  ? AutomaticCardUserDTO
  : T extends CARD_DATA_SOURCE.MODULE
  ? AutomaticCardModuleDTO
  : T extends CARD_DATA_SOURCE.PROGRAM
  ? AutomaticCardProgramDTO
  : T extends CARD_DATA_SOURCE.BULLETIN
  ? AutomaticCardBulletinDTO
  : T extends CARD_DATA_SOURCE.CALENDAR
  ? AutomaticCardCalendarDTO
  : T extends CARD_DATA_SOURCE.STATISTICS
  ? AutomaticCardStatisticsDTO
  : AutomaticCardBaseDTO<T>;

export interface AutomaticCardBaseDTO<T = CARD_DATA_SOURCE> extends CardBaseDTO<CARD_TYPE.AUTOMATIC> {
  dataSource: T;
  dataSourceStatistics?: CARD_DATA_SOURCE_STATISTICS;
}

export type CardDTO<T = CARD_TYPE> = T extends CARD_TYPE.AUTOMATIC ? AutomaticCardDTO : CardBaseDTO<T>;

export interface CardBaseDTO<T = CARD_TYPE> {
  date?: string;
  description?: string;
  id: number;
  imageUrl?: string;
  links?: LinkDTO;
  number?: any;
  quote?: { text: string; author: { id?: number; name: string; picture: string; role: string } };
  officesToBeVisibleTo?: string[];
  officesNotToBeVisible?: string[];
  rolesVisibility?: ROLE[];
  slug?: string;
  svgCode?: string;
  title?: string;
  timer?: boolean;
  visibleFrom?: string;
  visibleUntil?: string;
  type?: T;
  userCountry?: string;
  weight?: number;
  youtube?: { input: string; video_id: string; videoThumbnail: string };
  poll?: PollDTO;
}

export interface LinkDTO {
  linkUrl: string;
  title: string;
}

export interface SidebarIconDTO {
  id: number;
  title: string;
  icon: string;
  links: LinkDTO;
  weight: number;
}

export interface ToolCategoryDTO {
  id: number;
  title: string;
}

export interface ToolButtonDTO {
  id: number;
  title: string;
  logoUrl: string;
  iconLinks: {
    title: string;
    linkUrl: string;
  };
}

export interface ToolItemDTO extends ToolButtonDTO {
  description: string;
  buttons: ToolButtonDTO[];
}

export interface ToolDescriptionDTO {
  summary: string;
  value: string;
}

export interface ToolDTO {
  description?: ToolDescriptionDTO;
  category: ToolCategoryDTO;
  id: number;
  logoUrl: string;
  links: LinkDTO;
  slug: string;
  title: string;
  type: EToolType;
  fieldAboutTextVisibility: 0 | 1;
  weight: number;
  buttons?: ToolButtonDTO[];
  items?: ToolItemDTO[];
}

export interface CountryDTO {
  id: number;
  code: string;
  title: string;
  functions: FunctionDTO[];
  languages: { [key: string]: string } | '';
}

export interface AddressDTO {
  countryCode: string;
  countryName: string;
  locality: string;
  addressLine1: string;
  addressLine2: string;
  dependentLocality: string | null;
  postalCode: string;
}

export interface PartialOfficeDTO {
  id: number;
  ownerId: number;
  status: OFFICE_STATUS;
  title: string;
  address: AddressDTO;
}

export interface FunctionDTO {
  id: FUNCTIONS;
  title: string;
}

export interface OfficeDTO {
  id: number;
  email: string;
  facebook: string;
  function: FunctionDTO;
  logoUrl: string;
  location: {
    lat: string;
    lon: string;
  };
  people: { id: number; name: string; image: string }[];
  title: string;
  status: OFFICE_STATUS;
  phoneNumber: string;
  website: string;
  address: AddressDTO;
}

export interface PersonDTO {
  id: number;
  name: string;
  private_email: string;
  public_email: string;
  user_status: USER_STATUS;
  phone: string;
  facebook: string;
  image: string;
  migrated: string;
  descriptions: {
    EN: string;
    HU: string;
  };
  // spokenLanguages should be an array I think
  spokenLanguages: {
    hu: 'Hungarian';
    en: 'English';
  };
  // structures should be an array I think
  structures: {
    ebl: number;
    eec: number;
  };
  country: CountryDTO;
  office: OfficeDTO;
  function: FunctionDTO;
  location: {
    lat: number;
    lon: number;
  };
  ed_permissions: string[];
  active: boolean;
}

export interface ModuleDTO {
  id: number;
  title: string;
  thumbnail: string | false;
  video: VideoDTO;
  files: FileDTO[];
  links: LinkDTO[];
  people: PersonDTO[];
  slug: string;
  keyArea: { id: number; title: string }[];
  contentBlocks: { [key: string]: any }[];
  prev: { [key: string]: any } | false;
  next: { [key: string]: any } | false;
}

export interface ModuleMinDTO {
  created: string;
  id: number;
  weight: number;
  slug: string;
  title: string;
  keyArea: { id: string };
  links: [];
  files: [];
  people: [];
  video: {
    videoThumbnail?: string;
    duration?: string;
  };
}

export interface KeyAreaDTO {
  id: number;
  title: string;
  slug: string;
  weight: number;
  modules: ModuleMinDTO[];
}

export interface GoogleEvent {
  description: string;
  googleLink: string;
  googleEventId: string;
  id: number;
  location: string;
  title: string;
  startTime: number;
  endTime: number;
}

export interface DefaultDrupalQueryParams {
  limit?: number;
  offset?: number;
  sort?: string;
  [key: string]: string | number | undefined;
}

export interface UsefulLinksLinkDTO {
  id: number;
  langcode: string;
  title: string;
  created: string;
  changed: string;
  uid: number;
  link: LinkDTO;
  weight: number;
}

export interface KnowledgeCentreLinkDTO {
  id: number;
  langcode: string;
  title: string;
  uid?: number;
  description: {
    value: string;
    summary: string;
  };
  weight: number;
  slug: string;
}

export interface KnowledgeCentreArticleDTO {
  id: number;
  langcode: string;
  title: string;
  created: string;
  changed: string;
  uid: number;
  description: {
    summary: string;
    value: string;
    processed?: string;
  };
  publishDate: string | null;
  weight: number | null;
  officesNotToBeVisible: number[];
  officesToBeVisibleTo: number[];
  rolesVisibility: ROLE[];
  readNextArticles: {
    id: number;
    langcode: string;
    title: string;
    description: {
      summary: string;
      value: string;
    };
    weight: number;
    slug: string;
  }[];
  relatedTool: {
    id: number;
    title: string;
    slug: string;
    type: string;
    description: {
      summary: string;
      value: string;
    };
    logoUrl: string;
    links: {
      title: string;
      linkUrl: string;
    };
    category: {
      id: number;
      title: string;
    };
    fieldAboutTextVisibility: number;
    rolesVisibility: ROLE[];
  };
  slug: string;
}

export interface GetUsersArgs extends DefaultDrupalQueryParams {
  keyword?: string;
  status?: USER_STATUS;
  country?: string;
}

export interface GetOfficesArgs extends DefaultDrupalQueryParams {
  country?: string;
  keyword?: string;
  status?: OFFICE_STATUS;
}

export interface GetUserArgs {
  id: string | number;
}

export interface UserProfileDTO {
  id: number;
  name: string;
  publicMail: string;
  privateMail: string;
  hidden: 1 | 0;
  phone: string;
  facebook: string;
  image: string;
  country: string;
  office?: PartialOfficeDTO;
  location?: { lat: number; lon: number };
  favoriteLanguages?: { [key: string]: string };
  roles: ROLE[];
  spokenLanguages: { [key: string]: string };
  descriptions: { [key: string]: string };
  userStatus: USER_STATUS;
  workAnniversary: string;
}

export interface DashboardCardsRequest {
  kind?: CARD_REQUEST_TYPE;
  status?: PUBLISHED_STATE;
  timer?: number | 'all';
}

export interface BulletinDTO {
  category: { id: number; title: string };
  created: string;
  id: number;
  imageUrl: string;
  slug: string;
  title: string;
}

export interface SingleBulletinDTO {
  category: { id: number; title: string };
  created: string;
  description: {
    format: string;
    summary: string;
    value: string;
  };
  id: number;
  imageUrl: string;
  slug: string;
  title: string;
}

export interface EventStatDTO {
  total: string;
  countries: string;
  organizations: string;
}

export interface EventDTO {
  id: number;
  title: string;
  location: {
    lat: number;
    lon: number;
  };
  date: {
    begin: string | null;
    end: string | null;
  };
  slug: string;
  address: {
    locality: string;
    dependentLocality: string | null;
    administrativeArea: string;
    postalCode: string;
    addressLine1: string;
  };
  description: string;
  eventLink: string;
  organiserFirstclassId: string;
  nrEurodeskPeople: number;
  nrOfExpectedParticipant: number;
  organiser: {
    id: number;
    title: string;
    address: {
      countryCode: string;
      locality: string;
      dependentLocality: string | null;
      postalCode: string;
      addressLine1: string;
      addressLine2: string;
    };
    location: {
      lat: number;
      lon: number;
    };
    function: {
      id: number;
      title: string;
      role: string;
    };
    permissions: string[];
    status: string;
    owner_id: number;
    facebook: string | null;
    website: string | null;
    email: string | null;
    phoneNumber: string | null;
    logoUrl: string;
    people: {
      id: number;
      name: string;
      image?: string;
    }[];
  };
  author: number;
  eventFormat: {
    id: number;
    title: string;
  };
  eventFormatOfActivity: {
    id: number;
    title: string;
  };
  eventStatus: {
    id: number;
    title: string;
  };
  eventTopicOfActivity: {
    id: number;
    title: string;
  };
  eventType: {
    id: number;
    title: string;
  };
  eventTypeOfActivity: {
    id: number;
    title: string;
  };
  eventYouthGoalOfActivity: {
    id: number;
    title: string;
  };
  isEYYEvent: boolean;
  language: string;
  status: string;
}

export interface ProgrammeDTO {
  id: number;
  image: { uri?: { url?: string }; thumbnail?: string; links?: { self?: { href?: string } } };
  langcode: string;
  programmeCategory: { name: string };
  slug: string;
  title: string;
  created: string;
  changed: string;
  nextDeadline: string[];
  translation: {
    status: TRANSLATION_STATUS;
  };
}

export interface SingleProgrammeDTO {
  ageFrom: null;
  ageTo: null;
  author: {
    id: number;
    name: string;
    publicMail: string;
    userStatus: string;
    phone: null;
    image: string;
    office: { title?: string };
  };
  changed: string;
  created: string;
  description: { value: string; format: string; summary: string };
  destinationCountries: { code: string; title: string; id: number }[];
  eligibleCountries: { code: string; title: string; id: number }[];
  end: null;
  id: number;
  image: {
    id?: number;
    langcode?: string;
    filename?: string;
    uri?: { value: string; url: string };
    filemime?: string;
    filesize?: number;
    created?: string;
    changed?: string;
    thumbnail?: string;
    data?: null;
    links: { self: { href: string } };
  };
  isTranslatable: boolean;
  langcode: string;
  nextDeadline: string[];
  programmecategory: { id: number; langcode: string; name: string; description: null; weight: number };
  programmeTarget: {
    drupal_internal__tid: number;
    langcode: string;
    name: string;
    description: null;
    weight: number;
    changed: number;
  }[];
  programmetopics: {
    changed: string;
    description: null;
    drupal_internal__tid: number;
    langcode: string;
    name: string;
    weight: number;
  }[];
  topics: {
    changed: string;
    description: {
      format: string;
      processed: string;
      value: string;
    } | null;
    id: number;
    langcode: string;
    name: string;
    weight: number;
  }[];
  programmeonlineonsite: PROGRAMME_ONLINE_ONSITE[];
  programmeType: { id: number; langcode: string; name: string; description: null; weight: number };
  programmeTypeOfActivity: { data: null; links: {}; name?: string; id: number };
  programmeStatus: string; // 'draft' | 'pending' | 'published'
  promoter: string;
  programmeReadmore: {
    options?: unknown[];
    title: string;
    uri: string;
  };
  referenceTitle: null;
  registrationDeadline: string | null;
  slug: string;
  start: null;
  title: string;
}

export interface ProgrammeRevisionDTO {
  id: number;
  title: string;
  timestamp: number;
  isDefaultRevision: number;
  langcode: string;
  bundle: string;
}

export type BatchUpdateRequest = Array<{
  id: string;
  entity_type: string;
  data: { [key: string]: any };
}>;

export interface BatchUpdateResponse {
  log: string[];
  error_count: number;
}

export interface UpdateCardsRequest extends Partial<CommunityCardValues> {
  id?: string;
}

export interface UpdateCardsResponse {
  id?: string;
  error?: string;
}

export interface RemoveCardsRequest {
  id: string;
  type?: string;
}

export interface UploadFileRequest extends UploadableFileDTO {
  id: string;
  type: string;
  field: string;
}

export interface UploadFileResponse {
  id?: string;
  error?: string;
}

export interface PermissionsResponse {
  [ROLE.EBL]: PERMISSION[];
  [ROLE.MULTIPLIER]: PERMISSION[];
  [ROLE.NC]: PERMISSION[];
  [ROLE.ADMIN]?: PERMISSION[];
  [ROLE.AUTHENTICATED]?: PERMISSION[];
  [ROLE.ANONYMOUS]?: PERMISSION[];
}

export interface GetFiltersResponse {
  categories: { [id: string]: string };
  themes: { [id: string]: string };
  eligible_countries: string[];
  destination_countries: string[];
  languages: string[];
}

export interface UserSpokenLanguage {
  value: string;
  label: string;
}

export interface UpdateUserRequest {
  id?: number;
  field_name: string;
  mail: string;
  field_office?: number;
  field_hidden?: boolean;
  field_country?: number | string;
  roles?: ROLE[];
  field_facebook_profile_url?: string;
  field_descriptions?: { [key: string]: string };
  field_favorite_languages?: string[];
  field_phone_number?: string;
  field_public_email?: string;
  field_spoken?: UserSpokenLanguage[];
  field_user_status?: USER_STATUS;
  field_work_anniversary?: string | null;
  pass?: string;
  name?: string;
}

export interface LocateOfficeRequest {
  title?: string;
  website?: string;
  phone_number?: string;
  address?: {
    locality?: string;
    address_line1?: string;
    address_line2?: string;
    postal_code?: string;
    country_code?: string;
  };
}

export interface UpdateOfficeResponse {
  id?: string;
  error?: string | boolean;
}

// TODO add more optional fields (for add/edit view)
export interface UpdateOfficeRequest {
  id?: number;
  title: string;
  field_email: string | null;
  field_country?: number | string;
  field_function?: number;
  field_phone_number: string | null;
  field_facebook?: string | null;
  field_office_status?: string; // 'approved' | 'pending' | 'disapproved' | 'hidden';
  field_logo?: string | null;
  field_language?: string;
  field_address: {
    address_line1: string;
    address_line2: string;
    country_code?: string;
    postal_code: string;
    locality: string;
  };
  field_location_at_map: {
    geo_type: string;
    lat?: number | null;
    lon?: number | null;
    latlon: string;
    value: string;
  };
  field_website?: {
    uri: string;
    title?: string | null;
    options?: [];
  };
}

export interface UpdateUserResponse {
  id?: string;
  error?: string | boolean;
}

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || ''}/api/v2`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    whoAmI: builder.query<UserProfileDTO, void>({
      query: () => '/user/profile',
    }),
    resetPassword: builder.mutation<any, { email: string }>({
      query: ({ email }) => {
        return { url: '/user/passwordReset', method: 'POST', body: { email } };
      },
    }),
    changePassword: builder.mutation<any, { uid: string; password: string; timestamp: string; hash: string }>({
      query: (body) => {
        return { url: '/user/passwordChange', method: 'POST', body };
      },
    }),
    checkEmail: builder.query<{ error: string | false }, { email: string }>({
      query: (params) => {
        return { url: '/user/emailCheck', method: 'GET', params };
      },
    }),
    getPermissions: builder.query<PermissionsResponse, void>({
      query: () => '/handbook/permissions',
    }),
    getFunctions: builder.query<FunctionDTO[], void>({
      query: () => '/functions',
    }),
    getUsers: builder.query<UserProfileDTO[], GetUsersArgs>({
      query: (params) => {
        return { url: '/users', method: 'GET', params };
      },
    }),
    getUser: builder.query<UserProfileDTO, GetUserArgs>({
      query: (params) => {
        return { url: `/users/${params.id}`, method: 'GET' };
      },
    }),
    getCountriesFunctions: builder.query<CountryDTO[], void>({
      query: () => '/country/functions',
    }),
    getCountries: builder.query<{ [key: string]: string }[], void>({
      query: () => '/handbook/countries',
    }),
    searchOffices: builder.query<Partial<OfficeDTO>[], { country: string; keyword: string; function: FUNCTIONS }>({
      query: (params) => {
        return { url: `/office/search`, method: 'GET', params };
      },
    }),
    locateOffice: builder.query<{ found: boolean; params: LocateOfficeRequest }, LocateOfficeRequest>({
      query: (params) => {
        return { url: '/office/locate', params };
      },
    }),
    getOffices: builder.query<GetOfficeResponse, GetOfficesArgs>({
      query: (params) => {
        return { url: `/offices`, method: 'GET', params };
      },
    }),
    getOffice: builder.query<OfficeDTO, { id?: number | string }>({
      query: ({ id }) => {
        return { url: `/offices/${id}`, method: 'GET' };
      },
    }),
    getUserPendingCount: builder.query<GetUserPendingCountResponse, { country?: string }>({
      query: (params) => {
        return { url: '/user/pendingCount', method: 'GET', params };
      },
    }),
    getOfficePendingCount: builder.query<GetUserPendingCountResponse, { country?: string }>({
      query: (params) => {
        return { url: '/office/pendingCount', method: 'GET', params };
      },
    }),
    getLanguages: builder.query<any[], void>({
      query: () => `/handbook/languages`,
    }),
    getAcademyKeyAreas: builder.query<KeyAreaDTO[], void>({
      query: () => '/academy/keyAreas',
    }),
    getAcademyKeyArea: builder.query<KeyAreaDTO, string>({
      query: (id) => `/academy/keyAreas/${id}`,
    }),
    getAcademyModules: builder.query<ModuleDTO[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: '/academy/modules', params };
      },
    }),
    getAcademyModuleData: builder.query<ModuleDTO, string>({
      query: (id) => `/academy/modules/${id}`,
    }),
    getAcademyEvents: builder.query<GoogleEvent[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: '/googleCalendar', params };
      },
    }),
    getPolls: builder.query<PollDTO[], void>({
      query: () => '/polls',
    }),
    getPoll: builder.query<PollDTO[], string>({
      query: (id) => `/poll/${id}`,
    }),
    voteOnPoll: builder.mutation<
      {
        error: string | false;
        action: string;
      },
      { id: string; payload: { [key: string]: any } }
    >({
      query: ({ id, payload }) => {
        return {
          url: `/poll_vote/${id}`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    getDashboardCards: builder.query<CardDTO[], DashboardCardsRequest>({
      query: (params) => {
        return { url: '/cards', method: 'GET', params };
      },
    }),
    getDashboardCard: builder.query<CardDTO, string>({
      query: (id) => `/cards/${id}`,
    }),
    getSidebarIcons: builder.query<SidebarIconDTO[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: '/sidebarIcons', params };
      },
    }),
    getSidebarIcon: builder.query<SidebarIconDTO, { id: string }>({
      query: ({ id }) => `/sidebarIcons/${id}`,
    }),
    addSidebarIcon: builder.mutation<
      { id?: string; error: string | false },
      { id?: string; payload: { [key: string]: any } }
    >({
      query: ({ payload, id }) => {
        return {
          url: id === 'new' ? `/add/sidebar_icons` : `/edit/sidebar_icons/${id}`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    deleteSidebarIcon: builder.mutation<{ error: string | false }, { id: string }>({
      query: ({ id }) => {
        return { url: `/delete/sidebar_icons/${id}`, method: 'GET' };
      },
    }),
    getTools: builder.query<ToolDTO[], void>({
      query: () => '/tools',
      transformResponse: (baseQueryReturnValue) =>
        (baseQueryReturnValue as ToolDTO[]).filter((i) => Object.keys(i).length),
    }),
    getFilters: builder.query<GetFiltersResponse, void>({
      queryFn: async () => {
        const data = await fetch(`https://programmes.eurodesk.eu/api/v1/filters/all`);
        const json = await data.json();
        return { data: json };
      },
    }),
    getToolData: builder.query<ToolDTO, { slug: string }>({
      query: ({ slug }) => `/tools?slug=${slug}`,
    }),
    getUsefulLinks: builder.query<UsefulLinksLinkDTO[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: '/usefulLinks', params };
      },
    }),
    getUsefulLink: builder.query<UsefulLinksLinkDTO, { id: string }>({
      query: ({ id }) => `/usefulLinks/${id}`,
    }),
    addLink: builder.mutation<{ id?: string; error: string | false }, { id?: string; payload: { [key: string]: any } }>(
      {
        query: ({ payload, id }) => {
          return {
            url: id === 'new' ? `/add/useful_links` : `/edit/useful_links/${id}`,
            method: 'POST',
            body: payload,
          };
        },
      }
    ),
    deleteLink: builder.mutation<{ error: string | false }, { id: string }>({
      query: ({ id }) => {
        return { url: `/delete/useful_links/${id}`, method: 'GET' };
      },
    }),
    getKnowledgeCenterLinks: builder.query<KnowledgeCentreLinkDTO[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: '/knowledgeCenter', params };
      },
    }),
    getKnowledgeCenterData: builder.query<KnowledgeCentreArticleDTO, { id: string }>({
      query: ({ id }) => `/knowledgeCenter/${id}`,
    }),
    addKnowledgeCenterArticle: builder.mutation<
      { id?: string; error: string | false },
      { id?: string; payload: { [key: string]: any } }
    >({
      query: ({ payload, id }) => {
        return {
          url: id === 'new' ? `/add/articles` : `/edit/articles/${id}`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    deleteKnowledgeCenterArticle: builder.mutation<{ error: string | false }, { id: string }>({
      query: ({ id }) => {
        return { url: `/delete/articles/${id}`, method: 'GET' };
      },
    }),
    batchUpdate: builder.mutation<BatchUpdateResponse, BatchUpdateRequest>({
      query: (body) => {
        return { url: '/batch/update', method: 'POST', body };
      },
    }),
    deleteFile: builder.mutation<UploadFileResponse, { id: string; type: string; field: string }>({
      query: ({ id, type, field }) => {
        return { url: `/file/delete/${type}/${id}/${field}`, method: 'GET' };
      },
    }),
    uploadFile: builder.mutation<UploadFileResponse, UploadFileRequest>({
      query: ({ id, type, field, ...rest }) => {
        return {
          url: `/file/upload/${type}/${id}/${field}`,
          method: 'POST',
          body: { ...rest, content: parseBase64ToDrupalFormat(rest.content) },
        };
      },
    }),
    updateCard: builder.mutation<UpdateCardsResponse, UpdateCardsRequest>({
      query: ({ type, id, ...rest }) => {
        const url = id === 'new' ? `/add/${type}` : `/edit/${type}/${id}`;

        return { url: url, method: 'POST', body: parseCardRequestData(rest) };
      },
    }),
    updateOffice: builder.mutation<UpdateOfficeResponse, UpdateOfficeRequest>({
      query: ({ id, ...rest }) => {
        const url = id ? `/edit/office/${id}` : `/add/office`;

        return { url, method: 'POST', body: rest };
      },
    }),
    updateUser: builder.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: ({ id, ...rest }) => {
        const url = id ? `/edit/user/${id}` : `/add/user`;

        return { url, method: 'POST', body: rest };
      },
    }),
    removeCard: builder.mutation<UpdateCardsResponse, RemoveCardsRequest>({
      query: ({ id, type }) => {
        return { url: `/delete/${type}/${id}`, method: 'GET' };
      },
    }),
    getBulletins: builder.query<BulletinDTO[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: '/bulletins', params };
      },
    }),
    getBulletin: builder.query<SingleBulletinDTO, BulletinRouteProps>({
      query: ({ id }) => `/bulletins/${id}`,
    }),
    getBulletinCategories: builder.query<any[], void>({
      query: () => `/taxonomy/bulletin_category`,
    }),
    addBulletin: builder.mutation<{ error: boolean; id: string }, { payload: { [key: string]: any }; id?: string }>({
      query: ({ payload, id }) => {
        return {
          url: id ? `/edit/bulletin/${id}` : '/add/bulletin',
          method: 'POST',
          body: payload,
        };
      },
    }),
    deleteBulletin: builder.mutation<{ error: string | false }, { id: string }>({
      query: ({ id }) => {
        return { url: `/delete/bulletin/${id}`, method: 'GET' };
      },
    }),
    getEventStats: builder.query<EventStatDTO, void>({
      query: () => `/stat/events`,
    }),
    getEvents: builder.query<EventDTO[], DefaultDrupalQueryParams>({
      query: (params) => {
        return { url: `/events`, params };
      },
    }),
    getEvent: builder.query<EventDTO, { id: string | undefined }>({
      query: ({ id }) => `/events/${id}`,
    }),
    addEvent: builder.mutation<
      { error: { [key: string]: any } | false; id?: string },
      { payload: { [key: string]: any }; id?: string }
    >({
      query: ({ payload, id }) => {
        return {
          url: id ? `/edit/event/${id}` : `/add/event`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    deleteEvent: builder.mutation<{ error: string | false }, { id: string }>({
      query: ({ id }) => {
        return { url: `/delete/event/${id}`, method: 'GET' };
      },
    }),
    getEventTypes: builder.query<any[], void>({
      query: () => `/taxonomy/event_type`,
    }),
    getEventFormats: builder.query<any[], void>({
      query: () => `/taxonomy/event_format`,
    }),
    getEventStatuses: builder.query<any[], void>({
      query: () => `/taxonomy/event_status`,
    }),
    getEventTypeOfActivity: builder.query<any[], void>({
      query: () => `/taxonomy/event_type_of_activity`,
    }),
    getEventTopicOfActivity: builder.query<any[], void>({
      query: () => `/taxonomy/event_topic_of_activity`,
    }),
    getEventFormatOfActivity: builder.query<any[], void>({
      query: () => `/taxonomy/event_format_of_activity`,
    }),
    getEventYouthGoalOfActivity: builder.query<any[], void>({
      query: () => `/taxonomy/event_youth_goal_of_activity`,
    }),
    getProgrammes: builder.query<any, any>({
      queryFn(params, api, extraOptions, baseQuery) {
        const { preferredTranslation, ...rest } = params;
        const translation = preferredTranslation ? `${String(preferredTranslation).toLowerCase()}/` : '';

        return baseQuery({
          url: `${process.env.REACT_APP_API_URL || ''}/${translation}api/v2/programmes`,
          params: rest,
        });
      },
    }),
    getFavoriteLanguageProgrammes: builder.query<any, any>({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const { favoriteLanguages, ...rest } = params;
        const favoriteLanguageArray = Object.entries(favoriteLanguages);

        const results = await Promise.all(
          favoriteLanguageArray.map((lang) => {
            const langcode = String(lang[0]).toLowerCase();

            return baseQuery({
              url: `${process.env.REACT_APP_API_URL || ''}/${langcode}/api/v2/programmes`,
              params: { [`filter[langcode]`]: langcode, ...rest },
            });
          })
        );

        return {
          data: results.map((i, index) => ({
            langcode: favoriteLanguageArray[index][0],
            language: favoriteLanguageArray[index][1],
            items: i?.data,
          })),
        };
      },
    }),
    getProgrammeData: builder.query<SingleProgrammeDTO, any>({
      async queryFn(params, api, extraOptions, baseQuery) {
        const { id, language } = params;
        const langcode = language ? `${String(language).toLowerCase()}/` : '';

        return baseQuery({
          url: `${process.env.REACT_APP_API_URL || ''}/${langcode}api/v2/programmes/${id}`,
        }) as { data: SingleProgrammeDTO };
      },
    }),
    getProgrammeRevisionHistory: builder.query<ProgrammeRevisionDTO[], any>({
      query: ({ id }) => `/revisions/list/node/${id}`,
    }),
    getProgrammeRevisionDiff: builder.query<{ [key: string]: any }, any>({
      query: ({ id, a, b }) => `/revisions/diff/node/${id}/${a}/${b}`,
    }),
    getProgrammeCategories: builder.query<any[], void>({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({ url: '/taxonomy/categories' });
        return {
          data: (result.data as { [key: string]: any }).filter((i: any) =>
            Object.keys(programmeCategoryIdMap).includes(String(i.id))
          ),
        };
      },
    }),
    getProgrammeSubcategories: builder.query<any[], { id: number | undefined }>({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const { id } = params;

        const result = await baseQuery({ url: '/taxonomy/categories' });
        return {
          data: (result.data as { [key: string]: any }).filter(
            (i: any) => !id || programmeCategoryIdMap[id as any].includes(i.id)
          ),
        };
      },
    }),
    getProgrammeCategoryBySubcategory: builder.query<any, { id: number | undefined }>({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const { id } = params;
        if (!id) return { data: null };

        const result = await baseQuery({ url: '/taxonomy/categories' });
        const parentId = Object.keys(programmeCategoryIdMap).find((i) => programmeCategoryIdMap[i].includes(id));
        return { data: (result.data as { [key: string]: any }).find((i: any) => String(i.id) === parentId) };
      },
    }),
    getProgrammeTarget: builder.query<any[], void>({
      query: () => `/taxonomy/programme_target`,
    }),
    getProgrammeTopics: builder.query<any[], void>({
      query: () => `/taxonomy/topics`,
    }),
    getProgrammeType: builder.query<any[], void>({
      query: () => `/taxonomy/programme_type`,
    }),
    getProgrammeTypeOfActivity: builder.query<any[], void>({
      query: () => `/taxonomy/programme_type_of_activity`,
    }),
    getAllProgrammeTranslations: builder.query<any, { id: string }>({
      query: ({ id }) => `/programmes/${id}/translations`,
    }),
    addProgramme: builder.mutation<
      { error: boolean; id: string },
      { payload: { [key: string]: any }; id?: string; language?: string; translationExists: boolean }
    >({
      query: ({ payload, id, language = '', translationExists }) => {
        const fieldNextDeadline = (payload[PROGRAMME_FORM_FIELDS.DeadlinesStart] || [])
          .map((i: any) => dayjs(i).utc().format('YYYY-MM-DDTHH:mm:ss'))
          .filter((i: any) => i !== 'Invalid Date');
        const fieldRegistrationDeadline = dayjs(payload[PROGRAMME_FORM_FIELDS.DeadlinesEnd])
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
        const fieldStart = dayjs(payload[PROGRAMME_FORM_FIELDS.ProgrammeStartDate]).utc().format('YYYY-MM-DDTHH:mm:ss');
        const fieldEnd = dayjs(payload[PROGRAMME_FORM_FIELDS.ProgrammeEndDate]).utc().format('YYYY-MM-DDTHH:mm:ss');

        return {
          url: id ? `/${translationExists ? 'edit' : 'add'}/programme/${id}/${language}` : '/add/programme',
          method: 'POST',
          body: {
            langcode: payload[PROGRAMME_FORM_FIELDS.Language]
              ? String(payload[PROGRAMME_FORM_FIELDS.Language]).toLowerCase()
              : undefined,
            title: payload[PROGRAMME_FORM_FIELDS.DisplayTitle] || undefined,
            field_age_from: payload[PROGRAMME_FORM_FIELDS.EligibleAgeGroupStart] || undefined,
            field_age_to: payload[PROGRAMME_FORM_FIELDS.EligibleAgeGroupEnd] || undefined,
            field_description: {
              summary: payload[PROGRAMME_FORM_FIELDS.PreviewText] || undefined,
              value: payload[PROGRAMME_FORM_FIELDS.ProgrammeDescription] || undefined,
            },
            field_destination_countries:
              (payload[PROGRAMME_FORM_FIELDS.DestinationCountries] || []).map((i: any) => i.value) || undefined,
            field_eligible_countries:
              (payload[PROGRAMME_FORM_FIELDS.EligibleCountries] || []).map((i: any) => i.value) || undefined,
            field_is_translatable: Boolean(payload[PROGRAMME_FORM_FIELDS.IsTranslatable]) || undefined,
            field_next_deadline: fieldNextDeadline.length ? fieldNextDeadline : undefined,
            field_registration_deadline:
              fieldRegistrationDeadline !== 'Invalid Date' ? fieldRegistrationDeadline : undefined,
            field_programmecategory: payload[PROGRAMME_FORM_FIELDS.ProgrammeSubcategory] || undefined,
            field_programme_target: payload[PROGRAMME_FORM_FIELDS.ProgrammeTarget] || undefined, //
            field_programmetopics: payload[PROGRAMME_FORM_FIELDS.Topics] || undefined, //
            field_programmeonlineonsite: payload[PROGRAMME_FORM_FIELDS.ProgrammeOnlineOnsite] || undefined, //
            field_programme_type: payload[PROGRAMME_FORM_FIELDS.ProgrammeType] || undefined,
            field_programme_type_of_activity:
              payload[PROGRAMME_FORM_FIELDS.TypeOfActivity] &&
              payload[PROGRAMME_FORM_FIELDS.TypeOfActivity] !== 'undefined'
                ? payload[PROGRAMME_FORM_FIELDS.TypeOfActivity]
                : undefined,
            field_promoter: payload[PROGRAMME_FORM_FIELDS.ProgrammePromoter] || undefined,
            field_reference_title: payload[PROGRAMME_FORM_FIELDS.ReferenceTitle] || undefined,
            field_start: fieldStart !== 'Invalid Date' ? fieldStart : undefined,
            field_end: fieldEnd !== 'Invalid Date' ? fieldEnd : undefined,
            field_programme_readmore: payload[PROGRAMME_FORM_FIELDS.ReadMore] || undefined, //
            field_programme_status: payload[PROGRAMME_FORM_FIELDS.Status].value || undefined,
            content_translation_outdated: payload[PROGRAMME_FORM_FIELDS.MarkTranslationsOutdated],
          },
        };
      },
    }),
    changeProgrammeStatus: builder.mutation<{ error: boolean }, { id: string; status?: PROGRAMME_STATUS }>({
      query: ({ id, status }) => {
        return {
          url: `/edit/programme/${id}`,
          method: 'POST',
          body: { field_programme_status: status || PROGRAMME_STATUS.ARCHIVED },
        };
      },
    }),
    deleteProgramme: builder.mutation<{ error: boolean }, { id: string }>({
      query: ({ id }) => {
        return { url: `/delete/programme/${id}`, method: 'GET' };
      },
    }),
  }),
});

export const {
  useWhoAmIQuery,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useCheckEmailQuery,
  useGetPermissionsQuery,
  useGetFunctionsQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useGetCountriesFunctionsQuery,
  useGetCountriesQuery,
  useSearchOfficesQuery,
  useLocateOfficeQuery,
  useGetOfficesQuery,
  useGetOfficeQuery,
  useGetUserPendingCountQuery,
  useGetOfficePendingCountQuery,
  useGetLanguagesQuery,
  useGetAcademyKeyAreasQuery,
  useGetAcademyKeyAreaQuery,
  useGetAcademyModulesQuery,
  useGetAcademyModuleDataQuery,
  useGetAcademyEventsQuery,
  useGetPollsQuery,
  useGetPollQuery,
  useVoteOnPollMutation,
  useGetDashboardCardsQuery,
  useGetDashboardCardQuery,
  useGetSidebarIconsQuery,
  useGetSidebarIconQuery,
  useAddSidebarIconMutation,
  useDeleteSidebarIconMutation,
  useGetToolsQuery,
  useGetFiltersQuery,
  useGetToolDataQuery,
  useGetUsefulLinksQuery,
  useGetUsefulLinkQuery,
  useAddLinkMutation,
  useDeleteLinkMutation,
  useGetKnowledgeCenterLinksQuery,
  useGetKnowledgeCenterDataQuery,
  useAddKnowledgeCenterArticleMutation,
  useDeleteKnowledgeCenterArticleMutation,
  useBatchUpdateMutation,
  useDeleteFileMutation,
  useUploadFileMutation,
  useUpdateCardMutation,
  useUpdateOfficeMutation,
  useUpdateUserMutation,
  useRemoveCardMutation,
  useGetBulletinsQuery,
  useGetBulletinQuery,
  useGetBulletinCategoriesQuery,
  useAddBulletinMutation,
  useDeleteBulletinMutation,
  useGetEventStatsQuery,
  useGetEventsQuery,
  useGetEventQuery,
  useAddEventMutation,
  useDeleteEventMutation,
  useGetEventTypesQuery,
  useGetEventFormatsQuery,
  useGetEventStatusesQuery,
  useGetEventTypeOfActivityQuery,
  useGetEventTopicOfActivityQuery,
  useGetEventFormatOfActivityQuery,
  useGetEventYouthGoalOfActivityQuery,
  useGetProgrammesQuery,
  useGetFavoriteLanguageProgrammesQuery,
  useGetProgrammeDataQuery,
  useGetProgrammeRevisionHistoryQuery,
  useGetProgrammeRevisionDiffQuery,
  useGetProgrammeCategoriesQuery,
  useGetProgrammeSubcategoriesQuery,
  useGetProgrammeCategoryBySubcategoryQuery,
  useGetProgrammeTargetQuery,
  useGetProgrammeTopicsQuery,
  useGetProgrammeTypeQuery,
  useGetProgrammeTypeOfActivityQuery,
  useGetAllProgrammeTranslationsQuery,
  useAddProgrammeMutation,
  useChangeProgrammeStatusMutation,
  useDeleteProgrammeMutation,
} = generalApi;
