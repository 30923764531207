import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { matchPath, NavLink } from 'react-router-dom';

import { I18N_LANGUAGES, langUrlBase } from '../../i18n';
import { isInternalLink, stripHref } from '../../utils/route';

import './Button.css';

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  activeClassName?: string;
  variant?: 'primary' | 'secondary' | 'light' | 'danger' | 'link' | 'link-secondary' | 'plain';
  size?: 'lg' | 'md' | 'circle';
  href?: string;
  title?: string;
  type?: 'submit' | 'button';
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onClick?(e: any): void;
  skipHrefParsing?: boolean;
  download?: boolean;
  target?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const Button = React.memo(
  React.forwardRef<any, ButtonProps>((props, ref) => {
    const {
      children,
      iconLeft,
      iconRight,
      variant = 'primary',
      size = 'md',
      title,
      href,
      className,
      type = 'button',
      activeClassName,
      skipHrefParsing = false,
      disabled,
      ...rest
    } = props;
    const BEM = clsx(variant === 'plain' ? 'plain' : `btn btn--${variant} btn--${size}`, className, {
      'btn--disabled': disabled,
    });

    const renderContent = useMemo(
      () => (
        <React.Fragment>
          {iconLeft && <span className="mr-2">{iconLeft}</span>}
          <span>{children}</span>
          {iconRight && <span className="ml-2">{iconRight}</span>}
        </React.Fragment>
      ),
      [children, iconLeft, iconRight]
    );

    // since internal url from drupal are also absolute. then to make these links work in localhost we need to purge the test servers domain from the url
    const strippedHref = useMemo(() => (skipHrefParsing ? href || '' : stripHref(href)), [href, skipHrefParsing]);

    // we need to strip the language from the current url to match href correctly
    const isUrlActive = useCallback(
      (internalMatch, location): boolean => {
        return !!matchPath(strippedHref, {
          exact: true,
          path:
            langUrlBase + location.pathname.replace(new RegExp(`^/(${Object.values(I18N_LANGUAGES).join('|')})`), ''),
        });
      },
      [strippedHref]
    );

    return href && isInternalLink(strippedHref) ? (
      <NavLink
        className={BEM}
        activeClassName={activeClassName}
        isActive={isUrlActive}
        ref={ref}
        to={strippedHref}
        {...rest}
      >
        {renderContent}
      </NavLink>
    ) : href ? (
      <a className={BEM} ref={ref} href={href} title={title} target="_blank" rel="noopener noreferrer" {...rest}>
        {renderContent}
      </a>
    ) : (
      <button className={BEM} disabled={disabled} ref={ref} type={type} {...rest}>
        {renderContent}
      </button>
    );
  })
);

Button.displayName = 'Button';
