import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogoBlue from '../../assets/images/logo-blue.png';
import { NAV_ROUTES } from '../../Routes';
import { useGetOfficePendingCountQuery, useGetUserPendingCountQuery } from '../../store/api/generalApi';
import { useAuthState } from '../../store/auth/authHooks';
import { selectMenuVisibility } from '../../store/general/generalSelectors';
import { toggleMenuVisibility } from '../../store/general/generalStore';
import { MANAGER_ROLES } from '../../utils/permissions';
import { useRoleGuard } from '../../utils/roleGuard';
import { Button } from '../button/Button';
import { Guard } from '../guard/GuardComponent';
import { IconBurger, IconCogwheel, IconCompass, IconCross, IconNotificationBell } from '../icon';
import { Image } from '../image/Image';
import { NotificationBubble } from '../notification-bubble/NotificationBubble';
import { Popup, PopupItem } from '../popup/Popup';

interface NavigationProps {
  isAuthenticated: boolean;
}

export const Navigation: React.FC<NavigationProps> = React.memo(({ isAuthenticated }) => {
  const menuVisibility = useSelector(selectMenuVisibility);
  const { allowedAppAccess } = useAuthState();
  const dispatch = useDispatch();

  const hasAccessToPendingRequests = useRoleGuard(MANAGER_ROLES);
  const { roleBasedCountry } = useAuthState();
  const { data: pendingProfiles } = useGetUserPendingCountQuery(
    { country: roleBasedCountry },
    { refetchOnMountOrArgChange: true }
  );
  const { data: pendingOffices } = useGetOfficePendingCountQuery(
    { country: roleBasedCountry },
    { refetchOnMountOrArgChange: true }
  );

  return isAuthenticated ? (
    <div className="flex items-center h-[42px] px-[42px] bg-yellow">
      <div className="flex flex-1 items-center justify-center print:justify-center md:justify-start h-6 px-6 gap-[27px] md:order-first">
        <Button href={NAV_ROUTES.DASHBOARD} variant="plain">
          <Image className="h-[17px] object-contain" src={LogoBlue} alt="Logo" />
        </Button>
        <div className="h-full border border-brightblue hidden print:hidden md:block"></div>
        <Button
          href="https://eurodesk.eu/"
          title="Visit eurodesk.eu"
          variant="plain"
          aria-label="Eurodesk Map"
          className="hidden print:hidden md:block"
        >
          <IconCompass size="1.5rem" />
        </Button>
      </div>
      {isAuthenticated && allowedAppAccess ? (
        <>
          <Guard matchRoles={MANAGER_ROLES}>
            <Button
              href={NAV_ROUTES.SETTINGS({ child: 'overview' })}
              className="mr-6 hidden md:block print:hidden"
              variant="plain"
              aria-label="Settings"
            >
              <IconCogwheel size="1.25rem" />
            </Button>
          </Guard>

          <Button className="mr-0 md:mr-6 relative print:hidden" variant="plain" aria-label="Notifications">
            <span className="block relative">
              <IconNotificationBell size="1.25rem" />
              {hasAccessToPendingRequests && (pendingProfiles?.pending || pendingOffices?.pending) ? (
                <NotificationBubble className="flex absolute -top-1 -right-1">{`${
                  Number(pendingProfiles?.pending || 0) + Number(pendingOffices?.pending || 0)
                }`}</NotificationBubble>
              ) : null}
            </span>
            {hasAccessToPendingRequests && (pendingProfiles?.pending || pendingOffices?.pending) ? (
              <Popup title="Notifications" direction="bottom" location={15}>
                {pendingProfiles?.pending ? (
                  <PopupItem href={NAV_ROUTES.PROFILE({ child: 'pending-profiles' })}>
                    {`There are ${pendingProfiles?.pending} new account requests waiting for approval`}
                    <div className={clsx('font-light text-sm pt-2')}>{`Today at ${dayjs().format('HH:mm')}`}</div>
                  </PopupItem>
                ) : null}
                {pendingOffices?.pending ? (
                  <PopupItem href={NAV_ROUTES.PROFILE({ child: 'pending-offices' })}>
                    {`There are ${pendingOffices?.pending} new office requests waiting for approval`}
                    <div className={clsx('font-light text-sm pt-2')}>{`Today at ${dayjs().format('HH:mm')}`}</div>
                  </PopupItem>
                ) : null}
              </Popup>
            ) : null}
          </Button>

          <Button
            className="order-first md:order-last print:hidden"
            variant="plain"
            aria-label="Toggle menu"
            onClick={() => dispatch(toggleMenuVisibility())}
          >
            {menuVisibility ? <IconCross size="1.25rem" /> : <IconBurger size="1.25rem" />}
          </Button>
        </>
      ) : null}
    </div>
  ) : null;
});
